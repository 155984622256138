
//= include jquery/dist/jquery.js
//= include bootstrap/dist/js/bootstrap.bundle.min.js
//= include jquery.maskedinput/src/jquery.maskedinput.js
//= include slick-carousel/slick/slick.min.js

//= include jquery-form-validator/form-validator/jquery.form-validator.js
//= include jquery-form-validator/form-validator/html5.js
//= include jquery-form-validator/form-validator/lang/ru.js

//= include video.js/dist/video.min.js

//= include vendor/lazysizes.min.js
//= include vendor/ls.unveilhooks.js

